import React, { useState } from "react";
import { TypeAnimation } from "react-type-animation";
import CountUp from "react-countup";

import { Link } from "react-router-dom";
// import redLogo from "../../media/images/dog-gif-1.gif";
// import logo from "../../media/images/ft-logo-1.png";
// import logo from "../../media/images/fluffy-touch-1.png";
// import logo from "../../media/images/ft-logo-01.png";
import logo from "../../media/images/ft-red.png";
// import img111 from "../../media/images/img111.jpg";
import img111 from "../../media/images/Pet-Grooming-For-Cats.png";
// import logo from "../../media/images/ft-br.png";
// import logo from "../../media/images/ft-logo-2.png";


import dog_grm from "../../media/images/pet-grooming.png";
// import cat_grm from "../../media/images/cat-grm.png";
import cat_grm from "../../media/images/pet-boarding.png";

import bath from "../../media/images/pet-bath.jpg";
import mats from "../../media/images/mats-removal.jpg";
import groom from "../../media/images/pet-grooming.jpg";
import nails from "../../media/images/pet-nail.jpg";
import haircut from "../../media/images/grooming-haircut.jpg";
import insects from "../../media/images/insects.jpg";
import massage from "../../media/images/pet-massage.jpg";
import TestimonialComponent from "../../components/user/TestimonialComponent";
import WhyUsComponent from "../../components/user/WhyUsComponent";
import ContactFixButton from "../../components/user/fixComponents/ContactFixButton";
import LandingPageForm from "../../components/user/LandingPageForm";
import LandingPageDynamicForm from "../../components/user/LandingPageDynamicForm";
import LandingPageBoardingForm from "../../components/user/LandingPageBoardingForm";
// import KeyWords from "../../components/user/KeyWords";
import ClientSaysComponent from "../../components/user/ClientSaysComponent";
// import LandingOffer from "../../components/LandingOffer";

const LandingPage = () => {

  

  const [petGroom, setPetGroom] = useState("Dog")
  const [petTraining, setPetTraining] = useState("Dog")


  const [readmore, setReadmore] = useState(false)
  

  // const [openForm, setOpenForm] = useState(false)
  
  // -------------------------------------------
  
  
  
  //----------------------offer popup --------------------

  // const [offer, setOffer] = useState(true);
  
  //----------------------offer  --------------------


  // ---------- booking form open/close state ----------------------
 
  // -------------------------------
  const [form, setForm] = useState(false)
  const [dynamicForm, setDynamicForm] = useState(false)
  const [boardingForm, setBoardingForm] = useState(false)
  const [pet, setPet] = useState("")
  const [service, setService] = useState("")
  
  
  return (
    <div className="landing-page">

{dynamicForm?
<LandingPageDynamicForm setDynamicForm={setDynamicForm} pet={pet} service={service}/>
:""}
{boardingForm?
<LandingPageBoardingForm setBoardingForm={setBoardingForm} service={service}/>
:""}
{
  form?
  <LandingPageForm setForm={setForm}/>
  :""
}
      
      {/* <PopupOffer popup={popup} setPopup={setPopup} /> */}

      
      
{/* <LandingPageForm open={openModal} onClose={() => setOpenModal(false)}/> */}

{/* <LandingOffer offer={offer} setOffer={setOffer}/> */}

      
      {/* ----------------------------------- header-main start ------------------------------ */}

      <div className="landing-head-top">
        <div className="landing-head-top-heading-1">
          Get Upto 40% Off On All Services
        </div>
        <div className="landing-head-top-heading-2">*Limited Period Offer</div>
      </div>
      <div className="landing-header">
      <div className="">

        {/* ----------------------------------- navBar start ------------------------------ */}

        <div className="landing-nav">
          <Link to="/" className="landing-nav-logo">
            <img src={logo} alt="nav-logo" />
            
          </Link>
          <div className="landing-nav-button">
            <Link target="_blank" to="tel:+919658189189">Book On Call</Link>
          </div>
        </div>


        {/* <div className="landing-nav">
          <Link to="/" className="landing-nav-logo">
            <img src={redLogo} alt="nav-logo" />
            <span>FLUFFY&#160;</span> TOUCH
          </Link>
          <div className="landing-nav-button">
            <Link target="_blank" to="tel:01204474869">Book On Call</Link>
          </div>
        </div> */}

        {/* ----------------------------------- navBar end ------------------------------ */}

        {/* ----------------------------------- header content start ------------------------------ */}

        <div className="landing-header-content-landing">
          {/* <div className="landing-header-col-1"></div> */}
          <div className="landing-header-content">
            <div className="landing-header-content-heading">
              <div className="landing-header-content-heading-1">
                {/* Pet Grooming At Home */}
                {/* Cage Free Boarding */}
                {/* Pet Walking Service */}

                <TypeAnimation
                    sequence={[
                      "Pet Grooming At Home",
                      3500,
                      // "Cage-Free Boarding",
                      // 3500,
                      "Pet Designer Hair",
                      3500,
                    ]}
                    speed={50}
                    repeat={Infinity}
                  />
                
                 <br />
                in
                <span>
                  &nbsp;
                  <TypeAnimation
                    sequence={[
                      "Delhi",
                      2500,
                      "Noida",
                      2500,
                      "Greater Noida",
                      2500,
                      "Gurugram",
                      2500,
                      "Ghaziabad",
                      2500,
                      "Faridabad",
                      2500,
                    ]}
                    speed={50}
                    repeat={Infinity}
                  />
                </span>
              </div>
              <div className="landing-header-content-heading-2">
                make your pet more clean & modern
              </div>
            </div>

            <div className="landing-header-content-buttons">
              <div className="landing-header-content-button">
                <Link onClick={e=>setForm(true)} to="">Book an Appointment</Link>
              </div>
              <div className="landing-header-content-button">
                <div>
                  <Link to="tel:9658189189">
                    <i class="fa-solid fa-phone-volume"></i> &nbsp; Call For
                    Offers & Bookings
                  </Link>
                </div>
              </div>
            </div>

            <div className="landing-header-content-category">
              <div className="landing-header-content-category-heading">
                BOOK GROOMING FOR YOUR PET
              </div>
              <div className="landing-header-content-category-links">
                <a
                  href="#price"
                  className="landing-header-content-category-link"
                  onClick={e=>{setPetGroom("Dog")}}
                  >
                  <div className="div">GROOMING</div>
                  <img src={dog_grm} alt="icon" />
                </a> 
                <a
                  href="#price"
                  className="landing-header-content-category-link"
                  onClick={e=>{setPetGroom("Boarding")}}
                >
                  <div className="div">BOARDING</div>
                  <img src={cat_grm} alt="icon" />
                </a>
              </div>
            </div>
          </div>
          <div className="landing-header-col-1"></div>
        </div>
</div>
        <svg
          id="clouds-bottom"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          width="100%"
          height="6vw"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <path
            d="M-5 100 Q 0 20 5 100 Z
      M0 100 Q 5 0 10 100
      M5 100 Q 10 30 15 100
      M10 100 Q 15 10 20 100
      M15 100 Q 20 30 25 100
      M20 100 Q 25 -10 30 100
      M25 100 Q 30 10 35 100
      M30 100 Q 35 30 40 100
      M35 100 Q 40 10 45 100
      M40 100 Q 45 50 50 100
      M45 100 Q 50 20 55 100
      M50 100 Q 55 40 60 100
      M55 100 Q 60 60 65 100
      M60 100 Q 65 50 70 100
      M65 100 Q 70 20 75 100
      M70 100 Q 75 45 80 100
      M75 100 Q 80 30 85 100
      M80 100 Q 85 20 90 100
      M85 100 Q 90 50 95 100
      M90 100 Q 95 25 100 100
      M95 100 Q 100 15 105 100 Z"
          ></path>
        </svg>

        {/* ----------------------------------- header content end ------------------------------ */}
      
      </div>

      {/* ----------------------------------- header-main end ------------------------------ */}

      
      {/* ----------------------------------- header-small start ------------------------------ */}
      <div className="header-small">
      <div className="header-small-img">
        <img src={img111} alt="" />
      </div>
      <div className="header-small-content">
      <div className="header-small-content-heading-1">
      <TypeAnimation
                    sequence={[
                      "Pet Grooming At Home",
                      3500,
                      // "Cage-Free Boarding",
                      // 3500,
                      "Pet Designer Hair Cut",
                      3500,
                    ]}
                    speed={50}
                    repeat={Infinity}
                  />
      </div>
      <div className="header-small-content-heading-2">in 
      <span>
                  &nbsp;
                  <TypeAnimation
                    sequence={[
                      "Delhi",
                      2500,
                      "Noida",
                      2500,
                      "Greater Noida",
                      2500,
                      "Gurugram",
                      2500,
                      "Ghaziabad",
                      2500,
                      "Faridabad",
                      2500,
                    ]}
                    speed={50}
                    repeat={Infinity}
                  />
                </span></div>

      </div>
      </div>
      {/* ----------------------------------- header-small end ------------------------------ */}


      {/* ----------------------------------- number start ------------------------------ */}

      <div className="header-numbers">
    <div>

        <div className="header-numbers-col">
          <div className="header-numbers-col-inc">
            <CountUp end={10} duration="5" enableScrollSpy />+
          </div>
          <div className="header-numbers-col-txt">
            Years of experience
          </div>
        </div>

        <div className="header-numbers-col">
          <div className="header-numbers-col-inc">
            <CountUp end={4} duration="4" enableScrollSpy />
            <span>.<CountUp end={9} duration="5" enableScrollSpy /> </span>/5
          </div>
          <div className="header-numbers-col-txt">
            {" "}
            Rating on Google
          </div>
        </div>


        <div className="header-numbers-col">
          <div className="header-numbers-col-inc">
            <CountUp end={10} duration="5" enableScrollSpy />+
          </div>
          <div className="header-numbers-col-txt">Available Cities</div>
        </div>
        
        <div className="header-numbers-col">
          <div className="header-numbers-col-inc">
            <CountUp end={25000} enableScrollSpy />+
          </div>
          <div className="header-numbers-col-txt">Happy Customers</div>
        </div>
      </div>
    </div>

      {/* ----------------------------------- number end ------------------------------ */}


      <ClientSaysComponent/>

        {/* price - start --------------------------------------------------------- */}

        <div className="package" id="price">
            <div>

                <div className="package-head">
                    {/* <div className="package-head-icon">
                        <i className="fa-solid fa-paw"></i>
                    </div> */}
                    {/* <div className="package-head-heading">Our <span>Grooming</span> & <span>Boarding</span></div> */}
                    <div className="package-head-heading">Our <span>Grooming</span> Services</div>
                    {/* <div className="package-head-text">We Offer The Best Grooming And Boarding Services For Your Pets.</div> */}
                    <div className="package-head-text">We Offer The Best Grooming Services For Your Pets.</div>
                </div>

                <div className="package-content">
                    
                    <div className="package-content-head-2">
                        <div 
                        onClick={e=>{setPetGroom("Dog")}}
                        className={
                            petGroom === "Dog"? "package-content-head-red":""
                        }>Dog</div>
                        
                        <div 
                        onClick={e=>{setPetGroom("Cat")}}
                        className={
                             petGroom === "Cat"? "package-content-head-red":""
                        }>Cat</div>

                        <div 
                        onClick={e=>{setPetGroom("Boarding")}}
                        className={
                             petGroom === "Boarding"? "package-content-head-red":""
                        }>Pet Boarding</div>

                    </div>

{petGroom === "Dog"?
                    <div className="package-content-list">
                        <div className="package-content-list-heading"><span>Dog Grooming</span> Packages</div>
                        
                        <div className="package-content-list-columns">

                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">BARK 'N BATH</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs.699</span> Rs. 599 </div>
                                    <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                    <div className="package-content-list-col-heading-3">Extra Add-ons (optional)</div>
                                    <div className="package-content-list-col-point">Ear & Eyes Cleaning - <b>Rs 199</b></div>
                                    <div className="package-content-list-col-point">Nail's Clipping - <b>Rs 249</b></div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet("Dog"); setService("BARK 'N BATH   -   Rs. 599/-")}} >Book Appointment</div>
                                </div>
                            </div>


                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">PAMPER PAWS</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs.899</span> Rs. 799 </div>
                                    <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                    <div className="package-content-list-col-point">Sanitary Clipping</div>
                                    <div className="package-content-list-col-point">Face Haircut</div>
                                    <div className="package-content-list-col-heading-3">Extra Add-ons (optional)</div>
                                    <div className="package-content-list-col-point">Ear & Eyes Cleaning - <b>Rs 199</b></div>
                                    <div className="package-content-list-col-point">Nail's Clipping - <b>Rs 249</b></div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet("Dog"); setService("PAMPER PAWS   -   Rs. 799/-")}} >Book Appointment</div>
                                </div>
                            </div>


                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">FUR FRESH SPA</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 1199</span> Rs. 999</div>
                                    <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                    <div className="package-content-list-col-point">Nail's Clipping</div>
                                    <div className="package-content-list-col-point">Teeth Cleaning</div>
                                    <div className="package-content-list-col-point">Sanitary Clipping</div>
                                    <div className="package-content-list-col-point">Combing & Brushing</div>
                                    <div className="package-content-list-col-point">Ear & Eyes Cleaning</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet("Dog"); setService("FUR FRESH SPA   -   Rs. 999/-")}} >Book Appointment</div>
                                </div>
                            </div>


                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">BASIC GROOMING</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 1499 </span> Rs. 1299</div>
                                    <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                    <div className="package-content-list-col-point">Nail's Clipping</div>
                                    <div className="package-content-list-col-point">Teeth Cleaning</div>
                                    <div className="package-content-list-col-point">Sanitary Clipping</div>
                                    <div className="package-content-list-col-point">Combing & Brushing</div>
                                    <div className="package-content-list-col-point">Ear & Eyes Cleaning</div>
                                    <div className="package-content-list-col-point">Minor Hair Cut</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet("Dog"); setService("BASIC GROOMING   -   Rs. 1,299/-")}} >Book Appointment</div>
                                </div>
                            </div>


                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">FULL BODY GROOMING</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 2199</span> Rs. 1799</div>
                                    <div className="package-content-list-col-point">Full Body Haircut</div>
                                    <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                    <div className="package-content-list-col-point">Nail's Clipping</div>
                                    <div className="package-content-list-col-point">Mouth Spray</div>
                                    <div className="package-content-list-col-point">Teeth Cleaning</div>
                                    <div className="package-content-list-col-point">Combing & Brushing</div>
                                    <div className="package-content-list-col-point">Ear & Eyes Cleaning</div>
                                    <div className="package-content-list-col-point">Sanitary Clipping</div>
                                    <div className="package-content-list-col-point">Spritz of Perfume</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet("Dog"); setService("FULL BODY GROOMING   -   Rs. 1,799/-")}} >Book Appointment</div>
                                </div>
                            </div>


                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">DELUXE BODY GROOMING</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 2799</span> Rs. 2399</div>
                                    <div className="package-content-list-col-point">Designer Haircut</div>
                                    <div className="package-content-list-col-point">Medicated Bath & Blow Dry</div>
                                    <div className="package-content-list-col-point">Nails Clipping</div>
                                    <div className="package-content-list-col-point">Teeth Cleaning</div>
                                    <div className="package-content-list-col-point">Ear & Eyes Cleaning</div>
                                    <div className="package-content-list-col-point">Combing & Brushing</div>
                                    <div className="package-content-list-col-point">Sanitary Clipping</div>
                                    <div className="package-content-list-col-point">Spritz of Perfume</div>
                                    <div className="package-content-list-col-heading-3" onClick={e=>setReadmore(!readmore)}>
                                        {
                                            readmore?"read less"
                                            :"read more"
                                        }
                                    </div>
{
    readmore?
<>
                                    <div className="package-content-list-col-point">Anti-Tick Spray</div>
                                    <div className="package-content-list-col-point">Full Body Massage</div>
                                    <div className="package-content-list-col-point">Mouth Freshener Spray</div>
</>
:""}
                                    <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet("Dog"); setService("DELUXE BODY GROOMING   -   Rs. 2,399/-")}} >Book Appointment</div>
                                </div>
                            </div>

                        </div>

                    </div>

:petGroom ==="Cat"?
                    <div className="package-content-list">
                        <div className="package-content-list-heading"><span>Cat Grooming</span> Packages</div>
                        
                        <div className="package-content-list-columns">

                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">BARK 'N BATH</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs.699</span> Rs. 599 </div>
                                    <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                    <div className="package-content-list-col-heading-3">Extra Add-ons (optional)</div>
                                    <div className="package-content-list-col-point">Ear & Eyes Cleaning - <b>Rs 199</b></div>
                                    <div className="package-content-list-col-point">Nail's Clipping - <b>Rs 249</b></div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet("Cat"); setService("BARK 'N BATH   -   Rs. 599/-")}} >Book Appointment</div>
                                </div>
                            </div>


                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">PAMPER PAWS</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs.899</span> Rs. 799 </div>
                                    <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                    <div className="package-content-list-col-point">Sanitary Clipping</div>
                                    <div className="package-content-list-col-point">Ear Cleaning</div>
                                    <div className="package-content-list-col-heading-3">Extra Add-ons (optional)</div>
                                    <div className="package-content-list-col-point">Ear & Eyes Cleaning - <b>Rs 199</b></div>
                                    <div className="package-content-list-col-point">Nail's Clipping - <b>Rs 249</b></div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet("Cat"); setService("PAMPER PAWS   -   Rs. 799/-")}} >Book Appointment</div>
                                </div>
                            </div>


                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">FUR FRESH SPA</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 1199</span> Rs. 999</div>
                                    <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                    <div className="package-content-list-col-point">Nail's Clipping</div>
                                    <div className="package-content-list-col-point">Teeth Cleaning</div>
                                    <div className="package-content-list-col-point">Sanitary Clipping</div>
                                    <div className="package-content-list-col-point">Combing & Brushing</div>
                                    <div className="package-content-list-col-point">Ear & Eyes Cleaning</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet("Cat"); setService("FUR FRESH SPA   -   Rs. 999/-")}} >Book Appointment</div>
                                </div>
                            </div>


                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">BASIC GROOMING</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 1499 </span> Rs. 1299</div>
                                    <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                    <div className="package-content-list-col-point">Nail's Clipping</div>
                                    <div className="package-content-list-col-point">Teeth Cleaning</div>
                                    <div className="package-content-list-col-point">Sanitary Clipping</div>
                                    <div className="package-content-list-col-point">Combing & Brushing</div>
                                    <div className="package-content-list-col-point">Ear & Eyes Cleaning</div>
                                    <div className="package-content-list-col-point">Minor Hair Cut</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet("Cat"); setService("BASIC GROOMING   -   Rs. 1,299/-")}} >Book Appointment</div>
                                </div>
                            </div>


                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">FULL BODY GROOMING</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 2199</span> Rs. 1799</div>
                                    <div className="package-content-list-col-point">Full Body Haircut</div>
                                    <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                    <div className="package-content-list-col-point">Mouth Spray</div>
                                    <div className="package-content-list-col-point">Teeth Cleaning</div>
                                    <div className="package-content-list-col-point">Combing & Brushing</div>
                                    <div className="package-content-list-col-point">Ear & Eyes Cleaning</div>
                                    <div className="package-content-list-col-point">Sanitary Clipping</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet("Cat"); setService("FULL BODY GROOMING   -   Rs. 1,799/-")}} >Book Appointment</div>
                                </div>
                            </div>


                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">DELUXE BODY GROOMING</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 2799</span> Rs. 2399</div>
                                    <div className="package-content-list-col-point">Designer Haircut</div>
                                    <div className="package-content-list-col-point">Medicated Bath & Blow Dry</div>
                                    <div className="package-content-list-col-point">Teeth Cleaning</div>
                                    <div className="package-content-list-col-point">Ear & Eyes Cleaning</div>
                                    <div className="package-content-list-col-point">Combing & Brushing</div>
                                    <div className="package-content-list-col-point">Sanitary Clipping</div>

                                    <div className="package-content-list-col-heading-3" onClick={e=>setReadmore(!readmore)}>
                                        {
                                            readmore?"read less"
                                            :"read more"
                                        }
                                    </div>
                                  
                                    {
    readmore?
<>
                                    <div className="package-content-list-col-point">Anti-Tick Spray</div>
                                    <div className="package-content-list-col-point">Full Body Massage</div>
                                    <div className="package-content-list-col-point">Mouth Freshener Spray</div>
                                    </>
:""}
     
<div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet("Cat"); setService("DELUXE BODY GROOMING   -   Rs. 2,399/-")}} >Book Appointment</div>
                                </div>
                            </div>

                        </div>

                    </div>
:petGroom ==="Boarding"?
                    <div className="package-content-list">
                        <div className="package-content-list-heading"><span>Pet Boarding</span> Price</div>
                        {/* <div className="package-content-list-heading-1">Get Free Pet Grooming (worth Rs. 2,200/-) on 7-Days Boarding Service</div> */}

                           
                        <div className="package-content-list-columns">

                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">Day Boarding</div>
                                    <div className="package-content-list-col-heading-4"><s>Rs. 799</s> Rs.499/-</div>
                                    <div className="package-content-list-col-point">8 Am to 6 Pm, 1 meal included</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setBoardingForm(true); setService("Day Boarding   -   Rs. 499/-")}} >Book Appointment</div>
                                </div>
                            </div>

                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">Per Day Boarding</div>
                                    <div className="package-content-list-col-heading-4"><s>Rs. 1,199</s> Rs.699/-</div>
                                    <div className="package-content-list-col-point">Any day of your choice</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setBoardingForm(true); setService("Per Day Boarding   -   Rs. 699/-")}} >Book Appointment</div>
                                </div>
                            </div>

                            {/* <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">Silver Plan</div>
                                    <div className="package-content-list-col-heading-4"><s>Rs. 6,500</s> Rs.3,999/-</div>
                                    <div className="package-content-list-col-point">Any 7 days in a year</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setBoardingForm(true); setService("Silver Plan   -   Rs. 3,999/-")}} >Book Appointment</div>
                                </div>
                            </div>

                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">Gold Plan</div>
                                    <div className="package-content-list-col-heading-4"><s>Rs. 12,000</s> Rs.6,999/-</div>
                                    <div className="package-content-list-col-point">Any 14 days in a year</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setBoardingForm(true); setService("Gold Plan   -   Rs. 6,999/-")}} >Book Appointment</div>
                                </div>
                            </div>

                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">Diamond Plan</div>
                                    <div className="package-content-list-col-heading-4"><s>Rs. 22,000</s> Rs.11,999/-</div>
                                    <div className="package-content-list-col-point">Any 30 days in a year</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setBoardingForm(true); setService("Diamond Plan   -   Rs. 11,999/-")}} >Book Appointment</div>
                                </div>
                            </div>

                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">Platinum </div>
                                    <div className="package-content-list-col-heading-4"><s>Rs. 60,000</s> Rs.29,999/-</div>
                                    <div className="package-content-list-col-point">Any 90 days in a year</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setBoardingForm(true); setService("Platinum   -   Rs. 29,999/-")}} >Book Appointment</div>
                                </div>
                            </div>

                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">Black Plan</div>
                                    <div className="package-content-list-col-heading-4"><s>Rs. 2,00,000</s> Rs.99,999/-</div>
                                    <div className="package-content-list-col-point">Long term 1 year plan (365 days)</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setBoardingForm(true); setService("Black Plan   -   Rs. 99,999/-")}} >Book Appointment</div>
                                </div>
                            </div> */}

                        </div>

      


                    </div>
                    :""
                    }



                </div>



                {/* ------------------------------------------------------------------- */}
                <div className="package-training-margin-top"></div>
                <div id="haircut" className="package-head">
                    {/* <div className="package-head-icon">
                        <i className="fa-solid fa-paw"></i>
                    </div> */}
                    <div  className="package-head-heading">Our Designer<span> Haircut</span></div>
                    <div className="package-head-text">We Offer The Best Haircut Services For Your Pets.</div>
                </div>


                <div className="package-content">
                    <div className="package-content-head">
                        <div 
                        onClick={e=>{setPetTraining("Dog")}}
                        className={
                            petTraining === "Dog"? "package-content-head-2-red":""
                        }>Dog Designer Hair Cutting</div>
                        <div 
                        onClick={e=>{setPetTraining("Cat")}}
                        className={
                            petTraining === "Cat"? "package-content-head-2-red":""
                        }>Cat Designer Hair Cutting</div>

                    </div>

{/* -------------------------------------------------------- */}


                 

                    <div className="package-content-list">
                        <div className="package-content-list-heading">{petTraining} Designer Hair Cutting Packages</div>
                        <div className="package-content-list-columns">

                            <div className={`package-content-list-col-img-${petTraining}`}>

                            </div>


                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1"> HAIR TRIMMING</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 1199</span> Rs. 999</div>
                                    <div className="package-content-list-col-point">Hair Trim</div>
                                    <div className="package-content-list-col-point">Nail Clipping</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet(petTraining); setService(" HAIR TRIMMING   -   Rs. 999/-")}} >Book Appointment</div>
                                </div>
                            </div>


                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">DESIGNER HAIR CUTTING</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 1799 </span> Rs. 1399</div>
                                    <div className="package-content-list-col-point">Designer Haircut</div>
                                    <div className="package-content-list-col-point">Nail Clipping</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet(petTraining); setService("DESIGNER HAIR CUTTING   -   Rs. 1399/-")}} >Book Appointment</div>
                                </div>
                            </div>


                        </div>
                    </div>


                </div>

                

            </div>
        </div>

        
        {/* price - end --------------------------------------------------------- */}
        


     
      {/* ----------------------------------- cat packages start ------------------------------ */}

      {/* <div className="landing-package-pet" id="boarding">
        <div className="landing-package-heading-1">
          Best <span> Pet Boarding Centre</span> in Noida, NCR <span></span>
        </div>
        <div className="landing-package-heading-2">
        </div>
        
        <div className="landing-boarding-cols">
        <div className="landing-boarding-col-1">
          <img src={boarding} alt="" />
        </div>
        <div className="landing-boarding-col-2">
        <div className="landing-boarding-col-2-heading"> ARE YOU LOOKING FOR BOARDING SERVICE TO YOUR PETS? WELL, YOU ARE ON THE RIGHT PLACE.</div>
        <div className="landing-boarding-col-2-points">
        <div className="landing-boarding-col-2-point"><i class="fa-solid fa-paw"></i> &nbsp; Pick up & Drop Available</div>
        <div className="landing-boarding-col-2-point"><i class="fa-solid fa-paw"></i> &nbsp; Cage-Free Boarding</div>
        <div className="landing-boarding-col-2-point"><i class="fa-solid fa-paw"></i> &nbsp; 2 Time Walking & Playing</div>
        <div className="landing-boarding-col-2-point"><i class="fa-solid fa-paw"></i> &nbsp; 24*7 Camera Live Feed</div>
        <div className="landing-boarding-col-2-point"><i class="fa-solid fa-paw"></i> &nbsp; Trained Care Taker</div>
        <div className="landing-boarding-col-2-point"><i class="fa-solid fa-paw"></i> &nbsp; Veg & Non Veg Food</div>
          
        </div>
        </div>

        </div>
        
      </div> */}

      {/* ----------------------------------- cat packages end ------------------------------ */}




      <div className="landing-package-grooming-service">
        <div className="grooming-service">
          <div className="landing-package-heading-1">
            {/* Popular Grooming Packages for DOGS */}
            {/* OUR <span> GROOMING & CARE</span> Packages for <span>CATS</span> */}
            Explore Best <span>Grooming </span> Services for Your{" "}
            <span>Pet</span>
          </div>

          {/* <div className="grooming-service-heading"><i class="fa-solid fa-paw"></i> Explore Best Grooming Services for Your Pet <i class="fa-solid fa-paw"></i></div> */}
          <div className="grooming-service-content">
            <div className="grooming-service-content-col">
              <div className="grooming-service-content-col-img">
                <img src={bath} alt="service" />
              </div>
              <div className="grooming-service-content-col-text">
                Pet Spa Bath
              </div>
            </div>

            <div className="grooming-service-content-col">
              <div className="grooming-service-content-col-img">
                <img src={mats} alt="service" />
              </div>
              <div className="grooming-service-content-col-text">
                Knots/Mats <br /> Removal
              </div>
            </div>

            <div className="grooming-service-content-col">
              <div className="grooming-service-content-col-img">
                <img src={groom} alt="service" />
              </div>
              <div className="grooming-service-content-col-text">
                Full Grooming
              </div>
            </div>

            <div className="grooming-service-content-col">
              <div className="grooming-service-content-col-img">
                <img src={nails} alt="service" />
              </div>
              <div className="grooming-service-content-col-text">
                Nail Clipping
              </div>
            </div>

            <div className="grooming-service-content-col">
              <div className="grooming-service-content-col-img">
                <img src={haircut} alt="service" />
              </div>
              <div className="grooming-service-content-col-text">
                Stylish Hair <br /> Cuts
              </div>
            </div>

            <div className="grooming-service-content-col">
              <div className="grooming-service-content-col-img">
                <img src={insects} alt="service" />
              </div>
              <div className="grooming-service-content-col-text">
                Anti-Tick <br /> Treatment
              </div>
            </div>

            <div className="grooming-service-content-col">
              <div className="grooming-service-content-col-img">
                <img src={massage} alt="service" />
              </div>
              <div className="grooming-service-content-col-text">
                DeStressing
                <br /> Massage
              </div>
            </div>
          </div>
        </div>
      </div>

      <WhyUsComponent />

      <div className="landing-package-testimonial">
        <TestimonialComponent />
      </div>

      {/* <KeyWords/> */}

      <div className="landing-footer">
        <svg
          id="clouds-bottom"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          width="100%"
          height="6vw"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <path
            d="M-5 100 Q 0 20 5 100 Z
      M0 100 Q 5 0 10 100
      M5 100 Q 10 30 15 100
      M10 100 Q 15 10 20 100
      M15 100 Q 20 30 25 100
      M20 100 Q 25 -10 30 100
      M25 100 Q 30 10 35 100
      M30 100 Q 35 30 40 100
      M35 100 Q 40 10 45 100
      M40 100 Q 45 50 50 100
      M45 100 Q 50 20 55 100
      M50 100 Q 55 40 60 100
      M55 100 Q 60 60 65 100
      M60 100 Q 65 50 70 100
      M65 100 Q 70 20 75 100
      M70 100 Q 75 45 80 100
      M75 100 Q 80 30 85 100
      M80 100 Q 85 20 90 100
      M85 100 Q 90 50 95 100
      M90 100 Q 95 25 100 100
      M95 100 Q 100 15 105 100 Z"
          ></path>
        </svg>

        <div className="landing-footer-cols">
          <div className="landing-footer-col-1">
            <Link to="/" className="landing-footer-col-1-logo">

              <img src={logo} alt="" />
              
              
              {/* <div className="landing-footer-logo-icon">
                <img src={redLogo} alt="logo" />
              </div>
              <div className="landing-footer-logo-text">
                <div className="landing-footer-logo-text-1">
                  FLUFFY<span>&nbsp;TOUCH</span>
                </div>
                <div className="landing-footer-logo-text-2">
                  your pet deserve the best
                </div>
              </div> */}
            </Link>

            <div className="landing-footer-col-1-footer-icon">
              <Link
                target="_blank"
                to="https://www.facebook.com/Fluffytouchoffc/"
              >
                <i class="fa-brands fa-facebook"></i>
              </Link>
              <Link
                target="_blank"
                to="https://www.instagram.com/fluffytouchofficial/?igsh=ZGNjOWZkYTE3MQ%3D%3D"
              >
                <i class="fa-brands fa-instagram"></i>
              </Link>
              <Link
                target="_blank"
                to="https://twitter.com/i/flow/login?redirect_after_login=%2Ffluffytouchoffc"
              >
                <i class="fa-brands fa-x-twitter"></i>
              </Link>
              <Link
                target="_blank"
                to="https://www.linkedin.com/company/fluffytouch/"
              >
                <i class="fa-brands fa-linkedin-in"></i>
              </Link>
              <Link
                target="_blank"
                to="https://www.youtube.com/channel/UCcUZcYkiAv8t5ruDxYvVn_A"
              >
                <i class="fa-brands fa-youtube"></i>
              </Link>
              <Link
                target="_blank"
                to="https://in.pinterest.com/indrani1941/?invite_code=ea98e9e9d26b4c7db4219951ca1b9250&sender=1094515653097968674"
              >
                <i class="fa-brands fa-pinterest"></i>
              </Link>
            </div>
          </div>

          <div className="landing-footer-col-2">
            <div className="landing-footer-col-2-col">
              <div className="landing-footer-col-2-col-heading">
                AVAILABLE IN
              </div>
              <div className="landing-footer-col-2-col-points">
                <div>Delhi</div>
                <div>Noida</div>
                <div>Greater Noida</div>
                <div>Gurugram</div>
                <div>Ghaziabad</div>
                <div>Faridabad</div>
              </div>
            </div>

            <div className="landing-footer-col-2-col">
              <div className="landing-footer-col-2-col-heading">EXPLORE</div>
              <div className="landing-footer-col-2-col-points">
                {/* <div>Pet Boarding</div> */}
                <a href="#price"  onClick={e=>{setPetGroom("Dog")}}>Dog Grooming</a>
                <a href="#price"  onClick={e=>{setPetGroom("Cat")}}>Cat Grooming</a>
                <a href="#haircut" onClick={e=>{setPetTraining("Dog")}}>Dog Hair Cut</a>
                <a href="#haircut" onClick={e=>{setPetTraining("Cat")}}>Cat Hair Cut</a>
                <a href="#why-us">Pet Training</a>
                <a href="#happy-client  ">Happy Clients</a>
                {/* <div>Pet Accessories</div>
                <div>Vet Care</div> */}
              </div>
            </div>

            {/* <div className="landing-footer-col-2-col">
              <div className="landing-footer-col-2-col-heading">
                ABOUT FLUFFY TOUCH
              </div>
              <div className="landing-footer-col-2-col-points">
                <div>
                  <span>
                    <i class="fa-solid fa-location-arrow"></i>
                  </span>
                  210, 2nd Floor, Ocean Plaza, <br />
                  Sector-18, Noida - 201301
                </div>
                <Link target="_blank" to="tel:01204474869">
                  <span>
                    <i class="fa-solid fa-mobile-screen-button"></i>
                  </span>{" "}
                  0120 4474869
                </Link>
                <Link target="_blank" to="mailto:info@fluffytouch.in">
                  <span>
                    <i class="fa-solid fa-envelope"></i>{" "}
                  </span>
                  info@fluffytouch.in
                </Link>
                <Link target="_blank" to="https://wa.me/+919658189189?text=Hello ! I'm Interested in Doorstep Pet Grooming Service. Please contact me.">
                  <span>
                    <i class="fa-brands fa-whatsapp"></i>
                  </span>{" "}
                  +91 9658-189-189
                </Link>
              </div>
            </div> */}
          </div>
        </div>

        <div className="landing-footer-cc">
          Copyright <i class="fa-regular fa-copyright"></i> 2019, FLUFFY TOUCH.
          All Rights Reserved.
        </div>
      </div>

      <ContactFixButton />
      
    </div>
  );
};

export default LandingPage;
