import React from "react";
import WhatsappButton from "./WhatsappButton";
import CallButton from "./CallButton";

const ContactFixButton = () => {
  return (
    <div>
      <div className="contact-button">
        <CallButton />
        <WhatsappButton />
      </div>
    </div>
  );
};

export default ContactFixButton;
