import React from "react";
import icon from "../../media/images/google-icon.png";
import { Link } from "react-router-dom";

const TestimonialComponent = () => {
  return (
    <div>
      <div id="happy-client" className="testimonial-component">
        <div className="testimonial-component-heading">Customers <span> Speaks</span></div>
        <div className="testimonial-component-header">
          <div className="testimonial-component-header-col-1">
            <div className="testimonial-component-header-col-1-heading">
              Customer testimonial on Google
            </div>
            <div className="testimonial-component-header-col-1-text">
              <span>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
              </span>
              &nbsp; 4.9 Rating on Google
            </div>
          </div>
          <div className="testimonial-component-header-col-2">
            <Link
              target="_blank"
              to="https://www.google.com/search?q=fluffytouch&oq=fluffytouch&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIGCAEQRRg7MgYIAhBFGDwyBggDEEUYPDIGCAQQRRg8MgYIBRBFGDwyBggGEEUYPDIGCAcQLhhA0gEIMzMwNGowajGoAgCwAgA&sourceid=chrome&ie=UTF-8#lrd=0x390ce55a721e23cd:0x6b99dacacec120a5,1,,,,"
              className="testimonial-component-header-col-2-button"
            >
              Review us on Google
            </Link>
          </div> 
        </div>

        <div className="testimonial-component-comments">

            {/* ---------- start ------------ */}
          <div className="testimonial-component-comments-col">
            <div className="testimonial-component-comments-col-g-icon">
              <img src={icon} alt="icon" />
            </div>
            <div className="testimonial-component-comments-col-start">
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
            </div>
            <div className="testimonial-component-comments-col-text">
              Fluffy Touch has been providing me with services for the past two
              years, and I’m always satisfied. The groomers at Fluffy Touch are
              the best experts in their work.. &nbsp;
              <Link target="_blank" to="https://maps.app.goo.gl/P5vHGtSDA5NTnAGK9">read more on Google..</Link>
            </div>
            <div className="testimonial-component-comments-col-name">Akash Sharma</div>
            <div className="testimonial-component-comments-col-date">11 months ago</div>
            <div className="testimonial-component-comments-col-name-icon"><img src="https://lh3.googleusercontent.com/a-/ALV-UjWvfcFfUBce_BSM1ipKbWzpjVSGezazt9K6RvSx3tYQjMI=w45-h45-p-rp-mo-br100" alt="profile" /></div>
          </div>
          {/* ---------- end ------------ */}

          

{/* ---------- start ------------ */}
          <div className="testimonial-component-comments-col">
            <div className="testimonial-component-comments-col-g-icon">
              <img src={icon} alt="" />
            </div>
            <div className="testimonial-component-comments-col-start">
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
            </div>
            <div className="testimonial-component-comments-col-text">
            Best pet groomer. Very polite and fabulous at grooming work, my 6 month old Siberian Husky enjoyed his first grooming section a lot with him. Mr. Sonu very..
            <br />
              &nbsp;<Link target="_blank" to="https://maps.app.goo.gl/s9THKCh2v8YKgC8r9">read more on Google...</Link>
            </div>
            <div className="testimonial-component-comments-col-name">MISU ODIA VLOGS</div>
            <div className="testimonial-component-comments-col-date">a year ago</div>
            <div className="testimonial-component-comments-col-name-icon"><img src="https://lh3.googleusercontent.com/a-/ALV-UjWFHcN13WMpZrlDx8461K7VHeGVgFmRA3X2bIMyK1tMBsc=w45-h45-p-rp-mo-br100" alt="profile" /></div>
          </div>
{/* ---------- end ------------ */}


{/* ---------- start ------------ */}
          <div className="testimonial-component-comments-col">
            <div className="testimonial-component-comments-col-g-icon">
              <img src={icon} alt="" />
            </div>
            <div className="testimonial-component-comments-col-start">
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
            </div>
            <div className="testimonial-component-comments-col-text">
            I took a deluxe grooming service for our two pets (German Shepherd). Full value for money. Wonderfully grooming done, Superb caring by groom.. 
            <br />
              &nbsp;<Link target="_blank" to="https://maps.app.goo.gl/cDwWgpDpupgUQJbJA">read more on Google...</Link>
            </div>
            <div className="testimonial-component-comments-col-name">Subodh Kumar</div>
            <div className="testimonial-component-comments-col-date">a year ago</div>
            <div className="testimonial-component-comments-col-name-icon"><img src="https://lh3.googleusercontent.com/a-/ALV-UjXGQKYtmia-GjO_Va2AO-fiAjaAodgvFpsIMNKejWU-SA=w45-h45-p-rp-mo-br100" alt="profile" /></div>
          </div>
{/* ---------- end ------------ */}


{/* ---------- start ------------ */}
          {/* <div className="testimonial-component-comments-col">
            <div className="testimonial-component-comments-col-g-icon">
              <img src={icon} alt="" />
            </div>
            <div className="testimonial-component-comments-col-start">
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
              <i class="fa-solid fa-star"></i> &nbsp;
            </div>
            <div className="testimonial-component-comments-col-text">
                My experience was the greatest I've ever had. My pets are so adorable. I'm really happy for my doggu. The entire Fluffy Touch team is incredible. My overall experience with Fluffy Touch has 
              &nbsp;<Link  to="https://maps.app.goo.gl/yfiSDbUxgoJ2FAun6">read more..</Link>
            </div>
            <div className="testimonial-component-comments-col-name">Tushar Chaudhary</div>
            <div className="testimonial-component-comments-col-date">a year ago</div>
            <div className="testimonial-component-comments-col-name-icon"><img src="https://lh3.googleusercontent.com/a-/ALV-UjXEVmknMutyOpGFVZra0F8DVRTrvRcpI0oLniqweOBQyUM=w45-h45-p-rp-mo-br100" alt="profile" /></div>
          </div> */}
{/* ---------- end ------------ */}


        </div>
      </div>
    </div>
  );
};

export default TestimonialComponent;
