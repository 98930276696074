import React, { useState } from "react";
// import { TypeAnimation } from "react-type-animation";
import CountUp from "react-countup";

import { Link } from "react-router-dom";
const OldComponents = () => {

  const [openModal, setOpenModal] = useState(false);

  const onClose = () => {
    setOpenModal(!openModal)
  };
  // ------------------- dog button state -----------------
  // ----------- button state --------
  // const [dogPawAdd, setDogPawAdd] = useState(false);

  // const dogPawAddReadMoreLess = () => {
  //   setDogPawAdd(!dogPawAdd);
  // };

  // ----------- button state --------
  const [dogFur, setDogFur] = useState(false);

  const dogFurReadMoreLess = () => {
    setDogFur(!dogFur);
  };
  // -------------
  const [dogFurAdd, setDogFurAdd] = useState(false);

  const dogFurAddReadMoreLess = () => {
    setDogFurAdd(!dogFurAdd);
  };

  // ----------- button state --------
  const [dogBasic, setDogBasic] = useState(false);

  const dogBasicReadMoreLess = () => {
    setDogBasic(!dogBasic);
  };
  // ------------
  const [dogBasicAdd, setDogBasicAdd] = useState(false);

  const dogBasicAddReadMoreLess = () => {
    setDogBasicAdd(!dogBasicAdd);
  };

  // ----------- button state --------
  const [dogFull, setDogFull] = useState(false);

  const dogFullReadMoreLess = () => {
    setDogFull(!dogFull);
  };
  // ------------
  const [dogFullAdd, setDogFullAdd] = useState(false);

  const dogFullAddReadMoreLess = () => {
    setDogFullAdd(!dogFullAdd);
  };

  // ----------- button state --------
  const [dogDeluxe, setDogDeluxe] = useState(false);

  const dogDeluxeReadMoreLess = () => {
    setDogDeluxe(!dogDeluxe);
  };
  // ------------------
  const [dogDeluxeAdd, setDogDeluxeAdd] = useState(false);

  const dogDeluxeAddReadMoreLess = () => {
    setDogDeluxeAdd(!dogDeluxeAdd);
  };

  // ------------------- cat button state -----------------
  // ----------- button state --------
  const [catFur, setCatFur] = useState(false);

  const catFurReadMoreLess = () => {
    setCatFur(!catFur);
  };
  //--------------


  // ----------- button state --------
  const [catBasic, setCatBasic] = useState(false);

  const catBasicReadMoreLess = () => {
    setCatBasic(!catBasic);
  };
  //------------

  // ----------- button state --------
  const [catFull, setCatFull] = useState(false);

  const catFullReadMoreLess = () => {
    setCatFull(!catFull);
  };
  //-----------

  // ----------- button state --------
  const [catDeluxe, setCatDeluxe] = useState(false);

  const catDeluxeReadMoreLess = () => {
    setCatDeluxe(!catDeluxe);
  };
  //--------------

  
  
  return (
    <div>
       {/* ----------------------------------- dog packages start ------------------------------ */}

       <div className="landing-package-pet" id="dog-groom">
        <div className="landing-package-heading-1">
          OUR <span> GROOMING & CARE</span> Packages for <span>DOGS</span>
        </div>
        <div className="landing-package-heading-2">
          WE OFFER THE BEST GROOMING AND CARE SERVICES FOR YOUR PETS.
        </div>
        <div className="landing-package-cols">
          {/* ------------------------- col start ---------------------- */}
         
          {/* ------------------------- col end ---------------------- */}

          {/* ------------------------- col start ---------------------- */}
          <div className="landing-package-cols-1">
            <div className="landing-package-cols-1-heading-1">
              <span>FUR FRESH SPA</span>
            </div>
            <div className="landing-package-cols-1-heading-2">
              {/* <span>Rs.1,999</span> Rs.999 */}

              <span><s>Rs.1,599</s></span> &nbsp;

              <span>
              <span>
            Rs.<CountUp start={1599} end={999} duration="3" enableScrollSpy />

              </span>
              </span>
               
            </div>
            <div className="landing-package-cols-1-points">
              {/* ----------- points ------------------- */}
              <div className="landing-package-cols-1-single-point">
                <div>
                  <i class="fa-solid fa-paw"></i>
                </div>
                Bath & Blow Dry
              </div>
              <div className="landing-package-cols-1-single-point">
                <div>
                  <i class="fa-solid fa-paw"></i>
                </div>
                Nail's Clipping
              </div>
              <div className="landing-package-cols-1-single-point">
                <div>
                  <i class="fa-solid fa-paw"></i>
                </div>
                Teeth Cleaning
              </div>
              <div className="landing-package-cols-1-single-point">
                <div>
                  <i class="fa-solid fa-paw"></i>
                </div>
                Sanitary Clipping
              </div>

              {/* ----------- view more start ------------ */}

              {dogFur && (
                <div>
                  <div className="landing-package-cols-1-single-point">
                    <div>
                      <i class="fa-solid fa-paw"></i>
                    </div>
                    Combing & Brushing
                  </div>
                  <div className="landing-package-cols-1-single-point">
                    <div>
                      <i class="fa-solid fa-paw"></i>
                    </div>
                    Ear & Eyes Cleaning
                  </div>
                </div>
              )}

              {/* ----------- view more end ------------ */}

              <button onClick={dogFurReadMoreLess}>
                {dogFur ? "View Less" : "View More"}
              </button>
            </div>
            <div className="landing-package-cols-1-heading-3">
              Limited Time Offer
            </div>
            <div className="landing-package-cols-1-add-on">
              <button onClick={dogFurAddReadMoreLess} className="landing-package-cols-1-add-on-heading">
                <div>
                <i class="fa-solid fa-list"></i> Add-ons {dogFurAdd ? ' (optional)' : ''}
                </div>
                <div>
                {dogFurAdd ? <i class="fa-solid fa-caret-down"></i> : <i class="fa-solid fa-caret-left"></i>}
                
                </div>
              </button>
              
              {/* ----------- view more start ------------ */}

              {dogFurAdd && (
                <div>
              
              <div className="landing-package-cols-1-add-on-points">
                <div className="landing-package-cols-1-add-on-point">
                  <div>
                    <i class="fa-solid fa-paw"></i>
                  </div>
                  Tick Removal by Hand – Rs.250
                </div>
                <div className="landing-package-cols-1-add-on-point">
                  <div>
                    <i class="fa-solid fa-paw"></i>
                  </div>
                  30 Min DeStressing Massage – Rs.350
                </div>
                <div className="landing-package-cols-1-add-on-point">
                  <div>
                    <i class="fa-solid fa-paw"></i>
                  </div>
                  DeMatting – Rs.250
                </div>
              </div>
              </div>
              )}

              {/* ----------- view more end ------------ */}

            </div>
            {/* <div className="landing-package-cols-1-size">
              <div className="landing-package-cols-1-size-small">
                <div>
                  <i class="fa-solid fa-dog"></i>
                </div>
                <div className="landing-package-cols-1-size-text">
                  Small Size (Under 30cm)
                  <div className="landing-package-cols-1-size-rupee">
                    <i class="fa-solid fa-indian-rupee-sign"></i> 999/-
                  </div>
                </div>
              </div>
              <div className="landing-package-cols-1-size-medium">
                <div>
                  <i class="fa-solid fa-dog"></i>
                </div>
                <div className="landing-package-cols-1-size-text">
                  Medium Size (30cm-50cm)
                  <div className="landing-package-cols-1-size-rupee">
                    <i class="fa-solid fa-indian-rupee-sign"></i> 1099/-
                  </div>
                </div>
              </div>
              <div className="landing-package-cols-1-size-large">
                <div>
                  <i class="fa-solid fa-dog"></i>
                </div>
                <div className="landing-package-cols-1-size-text">
                  Large Size (Above 50cm)
                  <div className="landing-package-cols-1-size-rupee">
                    <i class="fa-solid fa-indian-rupee-sign"></i> 1199/-
                  </div>
                </div>
              </div>
            </div> */}
            <div className="landing-package-cols-1-button">
              {" "}
              <Link onClick={onClose} to="">Book Appointment</Link>
            </div>
          </div>
          {/* ------------------------- col end ---------------------- */}

          {/* ------------------------- col start ---------------------- */}
          <div className="landing-package-cols-1">
            <div className="landing-package-cols-1-heading-1">
              <span>DOG BASIC GROOMING</span>
            </div>
            <div className="landing-package-cols-1-heading-2">
              {/* <span>Rs.2,399</span> Rs.1,299 */}

              <span><s>Rs.1,899</s></span> &nbsp;

              <span>
              <span>
            Rs.<CountUp start={1899} end={1299} duration="3" enableScrollSpy />

              </span>
              </span>
              
            </div>
            <div className="landing-package-cols-1-points">
              {/* ----------- points ------------------- */}
              <div className="landing-package-cols-1-single-point">
                <div>
                  <i class="fa-solid fa-paw"></i>
                </div>
                Bath & Blow Dry
              </div>
              <div className="landing-package-cols-1-single-point">
                <div>
                  <i class="fa-solid fa-paw"></i>
                </div>
                Nail's Clipping
              </div>
              <div className="landing-package-cols-1-single-point">
                <div>
                  <i class="fa-solid fa-paw"></i>
                </div>
                Teeth Cleaning
              </div>
              <div className="landing-package-cols-1-single-point">
                <div>
                  <i class="fa-solid fa-paw"></i>
                </div>
                Sanitary Clipping
              </div>

              {/* ----------- view more start ------------ */}

              {dogBasic && (
                <div>
                  <div className="landing-package-cols-1-single-point">
                    <div>
                      <i class="fa-solid fa-paw"></i>
                    </div>
                    Combing & Brushing
                  </div>
                  <div className="landing-package-cols-1-single-point">
                    <div>
                      <i class="fa-solid fa-paw"></i>
                    </div>
                    Ear & Eyes Cleaning
                  </div>
                  <div className="landing-package-cols-1-single-point">
                    <div>
                      <i class="fa-solid fa-paw"></i>
                    </div>
                    Minor Hair Cut
                  </div>
                </div>
              )}

              {/* ----------- view more end ------------ */}

              <button onClick={dogBasicReadMoreLess}>
                {dogBasic ? "View Less" : "View More"}
              </button>
            </div>
            <div className="landing-package-cols-1-heading-3">
              Limited Time Offer
            </div>
            <div className="landing-package-cols-1-add-on">
              <button onClick={dogBasicAddReadMoreLess} className="landing-package-cols-1-add-on-heading">
                <div>
                <i class="fa-solid fa-list"></i> Add-ons {dogBasicAdd ? ' (optional)' : ''}
                </div>
                <div>
                {dogBasicAdd ? <i class="fa-solid fa-caret-down"></i> : <i class="fa-solid fa-caret-left"></i>}
                
                </div>
              </button>
              
              {/* ----------- view more start ------------ */}

              {dogBasicAdd && (
                <div>
              
              <div className="landing-package-cols-1-add-on-points">
                <div className="landing-package-cols-1-add-on-point">
                  <div>
                    <i class="fa-solid fa-paw"></i>
                  </div>
                  Tick Removal by Hand – Rs.250
                </div>
                <div className="landing-package-cols-1-add-on-point">
                  <div>
                    <i class="fa-solid fa-paw"></i>
                  </div>
                  30 Min DeStressing Massage – Rs.350
                </div>
                <div className="landing-package-cols-1-add-on-point">
                  <div>
                    <i class="fa-solid fa-paw"></i>
                  </div>
                  DeMatting – Rs.250
                </div>
              </div>
              </div>
              )}

              {/* ----------- view more end ------------ */}

            </div>
            {/* <div className="landing-package-cols-1-size">
              <div className="landing-package-cols-1-size-small">
                <div>
                  <i class="fa-solid fa-dog"></i>
                </div>
                <div className="landing-package-cols-1-size-text">
                  Small Size (Under 30cm)
                  <div className="landing-package-cols-1-size-rupee">
                    <i class="fa-solid fa-indian-rupee-sign"></i> 1,299/-
                  </div>
                </div>
              </div>
              <div className="landing-package-cols-1-size-medium">
                <div>
                  <i class="fa-solid fa-dog"></i>
                </div>
                <div className="landing-package-cols-1-size-text">
                  Medium Size (30cm-50cm)
                  <div className="landing-package-cols-1-size-rupee">
                    <i class="fa-solid fa-indian-rupee-sign"></i> 1,399/-
                  </div>
                </div>
              </div>
              <div className="landing-package-cols-1-size-large">
                <div>
                  <i class="fa-solid fa-dog"></i>
                </div>
                <div className="landing-package-cols-1-size-text">
                  Large Size (Above 50cm)
                  <div className="landing-package-cols-1-size-rupee">
                    <i class="fa-solid fa-indian-rupee-sign"></i> 1,499/-
                  </div>
                </div>
              </div>
            </div> */}
            <div className="landing-package-cols-1-button">
              {" "}
              <Link onClick={onClose} to="">Book Appointment</Link>
            </div>
          </div>
          {/* ------------------------- col end ---------------------- */}

          {/* ------------------------- col start ---------------------- */}
          <div className="landing-package-cols-1">
            <div className="landing-package-cols-1-heading-1">
              <span>DOG FULL BODY GROOMING</span>
            </div>
            <div className="landing-package-cols-1-heading-2">
              {/* <span>Rs.3,499</span> Rs.1,799 */}

              <span><s>Rs.2,899</s></span> &nbsp;

              <span>
              <span>
            Rs.<CountUp start={2899} end={1799} duration="3" enableScrollSpy />

              </span>
              </span>
              
            </div>
            <div className="landing-package-cols-1-points">
              {/* ----------- points ------------------- */}
              <div className="landing-package-cols-1-single-point">
                <div>
                  <i class="fa-solid fa-paw"></i>
                </div>
                Full Body Haircut
              </div>
              <div className="landing-package-cols-1-single-point">
                <div>
                  <i class="fa-solid fa-paw"></i>
                </div>
                Bath & Blow Dry
              </div>
              <div className="landing-package-cols-1-single-point">
                <div>
                  <i class="fa-solid fa-paw"></i>
                </div>
                Nail's Clipping
              </div>
              <div className="landing-package-cols-1-single-point">
                <div>
                  <i class="fa-solid fa-paw"></i>
                </div>
                Mouth Spray
              </div>

              {/* ----------- view more start ------------ */}

              {dogFull && (
                <div>
                  <div className="landing-package-cols-1-single-point">
                    <div>
                      <i class="fa-solid fa-paw"></i>
                    </div>
                    Teeth Cleaning
                  </div>
                  <div className="landing-package-cols-1-single-point">
                    <div>
                      <i class="fa-solid fa-paw"></i>
                    </div>
                    Combing & Brushing
                  </div>
                  <div className="landing-package-cols-1-single-point">
                    <div>
                      <i class="fa-solid fa-paw"></i>
                    </div>
                    Ear & Eyes Cleaning
                  </div>
                  <div className="landing-package-cols-1-single-point">
                    <div>
                      <i class="fa-solid fa-paw"></i>
                    </div>
                    Sanitary Clipping
                  </div>
                  <div className="landing-package-cols-1-single-point">
                    <div>
                      <i class="fa-solid fa-paw"></i>
                    </div>
                    Spritz of Perfume
                  </div>
                </div>
              )}

              {/* ----------- view more end ------------ */}

              <button onClick={dogFullReadMoreLess}>
                {dogFull ? "View Less" : "View More"}
              </button>
            </div>
            <div className="landing-package-cols-1-heading-3">
              Limited Time Offer
            </div>
            <div className="landing-package-cols-1-add-on">
              <button onClick={dogFullAddReadMoreLess} className="landing-package-cols-1-add-on-heading">
                <div>
                <i class="fa-solid fa-list"></i> Add-ons {dogFullAdd ? ' (optional)' : ''}
                </div>
                <div>
                {dogFullAdd ? <i class="fa-solid fa-caret-down"></i> : <i class="fa-solid fa-caret-left"></i>}
                
                </div>
              </button>
              
              {/* ----------- view more start ------------ */}

              {dogFullAdd && (
                <div>
              
              <div className="landing-package-cols-1-add-on-points">
                <div className="landing-package-cols-1-add-on-point">
                  <div>
                    <i class="fa-solid fa-paw"></i>
                  </div>
                  Tick Removal by Hand – Rs.250
                </div>
                <div className="landing-package-cols-1-add-on-point">
                  <div>
                    <i class="fa-solid fa-paw"></i>
                  </div>
                  30 Min DeStressing Massage – Rs.350
                </div>
                <div className="landing-package-cols-1-add-on-point">
                  <div>
                    <i class="fa-solid fa-paw"></i>
                  </div>
                  DeMatting – Rs.250
                </div>
              </div>
              </div>
              )}

              {/* ----------- view more end ------------ */}

            </div>
            {/* <div className="landing-package-cols-1-size">
              <div className="landing-package-cols-1-size-small">
                <div>
                  <i class="fa-solid fa-dog"></i>
                </div>
                <div className="landing-package-cols-1-size-text">
                  Small Size (Under 30cm)
                  <div className="landing-package-cols-1-size-rupee">
                    <i class="fa-solid fa-indian-rupee-sign"></i> 1,799/-
                  </div>
                </div>
              </div>
              <div className="landing-package-cols-1-size-medium">
                <div>
                  <i class="fa-solid fa-dog"></i>
                </div>
                <div className="landing-package-cols-1-size-text">
                  Medium Size (30cm-50cm)
                  <div className="landing-package-cols-1-size-rupee">
                    <i class="fa-solid fa-indian-rupee-sign"></i> 1,999/-
                  </div>
                </div>
              </div>
              <div className="landing-package-cols-1-size-large">
                <div>
                  <i class="fa-solid fa-dog"></i>
                </div>
                <div className="landing-package-cols-1-size-text">
                  Large Size (Above 50cm)
                  <div className="landing-package-cols-1-size-rupee">
                    <i class="fa-solid fa-indian-rupee-sign"></i> 2,199/-
                  </div>
                </div>
              </div>
            </div> */}
            <div className="landing-package-cols-1-button">
              {" "}
              <Link onClick={onClose}  to="">Book Appointment</Link>
            </div>
          </div>
          {/* ------------------------- col end ---------------------- */}

          {/* ------------------------- col start ---------------------- */}
          <div className="landing-package-cols-1">
            <div className="landing-package-cols-1-heading-1">
              <span>DOG DELUXE BODY GROOMING</span>
            </div>
            <div className="landing-package-cols-1-heading-2">
              {/* <span>Rs.4,799</span> Rs.2,399 */}

              <span><s>Rs.3,599</s></span> &nbsp;

              <span>
              <span>
            Rs.<CountUp start={3599} end={2399} duration="3" enableScrollSpy />

              </span>
              </span>
              
              
            </div>
            <div className="landing-package-cols-1-points">
              {/* ----------- points ------------------- */}
              <div className="landing-package-cols-1-single-point">
                <div>
                  <i class="fa-solid fa-paw"></i>
                </div>
                Designer Haircut
              </div>
              <div className="landing-package-cols-1-single-point">
                <div>
                  <i class="fa-solid fa-paw"></i>
                </div>
                Medicated Bath & Blow Dry
              </div>
              <div className="landing-package-cols-1-single-point">
                <div>
                  <i class="fa-solid fa-paw"></i>
                </div>
                Nails Clipping
              </div>
              <div className="landing-package-cols-1-single-point">
                <div>
                  <i class="fa-solid fa-paw"></i>
                </div>
                Teeth Cleaning
              </div>

              {/* ----------- view more start ------------ */}

              {dogDeluxe && (
                <div>
                  <div className="landing-package-cols-1-single-point">
                    <div>
                      <i class="fa-solid fa-paw"></i>
                    </div>
                    Ear & Eyes Cleaning
                  </div>
                  <div className="landing-package-cols-1-single-point">
                    <div>
                      <i class="fa-solid fa-paw"></i>
                    </div>
                    Combing & Brushing
                  </div>
                  <div className="landing-package-cols-1-single-point">
                    <div>
                      <i class="fa-solid fa-paw"></i>
                    </div>
                    Sanitary Clipping
                  </div>
                  <div className="landing-package-cols-1-single-point">
                    <div>
                      <i class="fa-solid fa-paw"></i>
                    </div>
                    Spritz of Perfume
                  </div>
                  <div className="landing-package-cols-1-single-point">
                    <div>
                      <i class="fa-solid fa-paw"></i>
                    </div>
                    Anti-Tick Spray
                  </div>
                  <div className="landing-package-cols-1-single-point">
                    <div>
                      <i class="fa-solid fa-paw"></i>
                    </div>
                    Full Body Massage
                  </div>
                  <div className="landing-package-cols-1-single-point">
                    <div>
                      <i class="fa-solid fa-paw"></i>
                    </div>
                    Mouth Freshener Spray
                  </div>
                </div>
              )}

              {/* ----------- view more end ------------ */}

              <button onClick={dogDeluxeReadMoreLess}>
                {dogDeluxe ? "View Less" : "View More"}
              </button>
            </div>
            <div className="landing-package-cols-1-heading-3">
              Limited Time Offer
            </div>


            <div className="landing-package-cols-1-add-on">
              <button onClick={dogDeluxeAddReadMoreLess} className="landing-package-cols-1-add-on-heading">
                <div>
                <i class="fa-solid fa-list"></i> Add-ons {dogDeluxeAdd ? ' (optional)' : ''}
                </div>
                <div>
                {dogDeluxeAdd ? <i class="fa-solid fa-caret-down"></i> : <i class="fa-solid fa-caret-left"></i>}
                
                </div>
              </button>
              
              {/* ----------- view more start ------------ */}

              {dogDeluxeAdd && (
                <div>
              
              <div className="landing-package-cols-1-add-on-points">
                <div className="landing-package-cols-1-add-on-point">
                  <div>
                    <i class="fa-solid fa-paw"></i>
                  </div>
                  Tick Removal by Hand – Rs.250
                </div>
                <div className="landing-package-cols-1-add-on-point">
                  <div>
                    <i class="fa-solid fa-paw"></i>
                  </div>
                  30 Min DeStressing Massage – Rs.350
                </div>
                <div className="landing-package-cols-1-add-on-point">
                  <div>
                    <i class="fa-solid fa-paw"></i>
                  </div>
                  DeMatting – Rs.250
                </div>
              </div>
              </div>
              )}

              {/* ----------- view more end ------------ */}

            </div>

            
            {/* <div className="landing-package-cols-1-size">
              <div className="landing-package-cols-1-size-small">
                <div>
                  <i class="fa-solid fa-dog"></i>
                </div>
                <div className="landing-package-cols-1-size-text">
                  Small Size (Under 30cm)
                  <div className="landing-package-cols-1-size-rupee">
                    <i class="fa-solid fa-indian-rupee-sign"></i> 2,399/-
                  </div>
                </div>
              </div>
              <div className="landing-package-cols-1-size-medium">
                <div>
                  <i class="fa-solid fa-dog"></i>
                </div>
                <div className="landing-package-cols-1-size-text">
                  Medium Size (30cm-50cm)
                  <div className="landing-package-cols-1-size-rupee">
                    <i class="fa-solid fa-indian-rupee-sign"></i> 2,599/-
                  </div>
                </div>
              </div>
              <div className="landing-package-cols-1-size-large">
                <div>
                  <i class="fa-solid fa-dog"></i>
                </div>
                <div className="landing-package-cols-1-size-text">
                  Large Size (Above 50cm)
                  <div className="landing-package-cols-1-size-rupee">
                    <i class="fa-solid fa-indian-rupee-sign"></i> 2,799/-
                  </div>
                </div>
              </div>
            </div> */}
            <div className="landing-package-cols-1-button">
              {" "}
              <Link onClick={onClose}  to="">Book Appointment</Link>
            </div>
          </div>
          {/* ------------------------- col end ---------------------- */}
        </div>
      </div>

      {/* -----------------------------------dog packages end ------------------------------ */}

      {/* ----------------------------------- cat packages start ------------------------------ */}

      <div className="landing-package-pet" id="cat-groom">
        <div className="landing-package-heading-1">
          {/* Popular Grooming Packages for DOGS */}
          OUR <span> GROOMING & CARE</span> Packages for <span>CATS</span>
        </div>
        <div className="landing-package-heading-2">
          WE OFFER THE BEST GROOMING AND CARE SERVICES FOR YOUR PETS.
        </div>
        <div className="landing-package-cols">
          {/* ------------------------- col start ---------------------- */}
         
         
          {/* ------------------------- col end ---------------------- */}

          {/* ------------------------- col start ---------------------- */}
          <div className="landing-package-cols-1">
            <div className="landing-package-cols-1-heading-1">
              <span>FUR FRESH SPA</span>
            </div>
            <div className="landing-package-cols-1-heading-2">
              {/* <span>Rs.1,999</span> Rs.999 */}
              
              <span><s>Rs.1,599</s></span> &nbsp;

              <span>
              <span>
            Rs.<CountUp start={1599} end={999} duration="3" enableScrollSpy />

              </span>
              </span>
              
            </div>
            <div className="landing-package-cols-1-points">
              {/* ----------- points ------------------- */}
              <div className="landing-package-cols-1-single-point">
                <div>
                  <i class="fa-solid fa-paw"></i>
                </div>
                Bath & Blow Dry
              </div>
              <div className="landing-package-cols-1-single-point">
                <div>
                  <i class="fa-solid fa-paw"></i>
                </div>
                Nail's Clipping
              </div>
              <div className="landing-package-cols-1-single-point">
                <div>
                  <i class="fa-solid fa-paw"></i>
                </div>
                Teeth Cleaning
              </div>
              <div className="landing-package-cols-1-single-point">
                <div>
                  <i class="fa-solid fa-paw"></i>
                </div>
                Sanitary Clipping
              </div>

              {/* ----------- view more start ------------ */}

              {catFur && (
                <div>
                  <div className="landing-package-cols-1-single-point">
                    <div>
                      <i class="fa-solid fa-paw"></i>
                    </div>
                    Combing & Brushing
                  </div>
                  <div className="landing-package-cols-1-single-point">
                    <div>
                      <i class="fa-solid fa-paw"></i>
                    </div>
                    Ear & Eyes Cleaning
                  </div>
                </div>
              )}

              {/* ----------- view more end ------------ */}

              <button onClick={catFurReadMoreLess}>
                {catFur ? "View Less" : "View More"}
              </button>
            </div>
            <div className="landing-package-cols-1-heading-3">
              Limited Time Offer
            </div>
            {/* <div className="landing-package-cols-1-size">
              <div className="landing-package-cols-1-size-small">
                <div>
                  <i class="fa-solid fa-dog"></i>
                </div>
                <div className="landing-package-cols-1-size-text">
                  Small Size (Under 30cm)
                  <div className="landing-package-cols-1-size-rupee">
                    <i class="fa-solid fa-indian-rupee-sign"></i> 999/-
                  </div>
                </div>
              </div>
              <div className="landing-package-cols-1-size-medium">
                <div>
                  <i class="fa-solid fa-dog"></i>
                </div>
                <div className="landing-package-cols-1-size-text">
                  Medium Size (30cm-50cm)
                  <div className="landing-package-cols-1-size-rupee">
                    <i class="fa-solid fa-indian-rupee-sign"></i> 1099/-
                  </div>
                </div>
              </div>
              <div className="landing-package-cols-1-size-large">
                <div>
                  <i class="fa-solid fa-dog"></i>
                </div>
                <div className="landing-package-cols-1-size-text">
                  Large Size (Above 50cm)
                  <div className="landing-package-cols-1-size-rupee">
                    <i class="fa-solid fa-indian-rupee-sign"></i> 1199/-
                  </div>
                </div>
              </div>
            </div> */}
            <div className="landing-package-cols-1-button">
              {" "}
              <Link onClick={onClose}  to="">Book Appointment</Link>
            </div>
          </div>
          {/* ------------------------- col end ---------------------- */}

          {/* ------------------------- col start ---------------------- */}
          <div className="landing-package-cols-1">
            <div className="landing-package-cols-1-heading-1">
              <span>CAT BASIC GROOMING</span>
            </div>
            <div className="landing-package-cols-1-heading-2">
              {/* <span>Rs.2,399</span> Rs.1,299 */}

              
              <span><s>Rs.1,899</s></span> &nbsp;

              <span>
              <span>
            Rs.<CountUp start={1899} end={1299} duration="3" enableScrollSpy />

              </span>
              </span>
              
              
              
            </div>
            <div className="landing-package-cols-1-points">
              {/* ----------- points ------------------- */}
              <div className="landing-package-cols-1-single-point">
                <div>
                  <i class="fa-solid fa-paw"></i>
                </div>
                Bath & Blow Dry
              </div>
              <div className="landing-package-cols-1-single-point">
                <div>
                  <i class="fa-solid fa-paw"></i>
                </div>
                Nail's Clipping
              </div>
              <div className="landing-package-cols-1-single-point">
                <div>
                  <i class="fa-solid fa-paw"></i>
                </div>
                Teeth Cleaning
              </div>
              <div className="landing-package-cols-1-single-point">
                <div>
                  <i class="fa-solid fa-paw"></i>
                </div>
                Sanitary Clipping
              </div>

              {/* ----------- view more start ------------ */}

              {catBasic && (
                <div>
                  <div className="landing-package-cols-1-single-point">
                    <div>
                      <i class="fa-solid fa-paw"></i>
                    </div>
                    Combing & Brushing
                  </div>
                  <div className="landing-package-cols-1-single-point">
                    <div>
                      <i class="fa-solid fa-paw"></i>
                    </div>
                    Ear & Eyes Cleaning
                  </div>
                  <div className="landing-package-cols-1-single-point">
                    <div>
                      <i class="fa-solid fa-paw"></i>
                    </div>
                    Minor HairCut
                  </div>
                </div>
              )}

              {/* ----------- view more end ------------ */}

              <button onClick={catBasicReadMoreLess}>
                {catBasic ? "View Less" : "View More"}
              </button>
            </div>
            <div className="landing-package-cols-1-heading-3">
              Limited Time Offer
            </div>
            {/* <div className="landing-package-cols-1-size">
              <div className="landing-package-cols-1-size-small">
                <div>
                  <i class="fa-solid fa-dog"></i>
                </div>
                <div className="landing-package-cols-1-size-text">
                  Small Size (Under 30cm)
                  <div className="landing-package-cols-1-size-rupee">
                    <i class="fa-solid fa-indian-rupee-sign"></i> 1,299/-
                  </div>
                </div>
              </div>
              <div className="landing-package-cols-1-size-medium">
                <div>
                  <i class="fa-solid fa-dog"></i>
                </div>
                <div className="landing-package-cols-1-size-text">
                  Medium Size (30cm-50cm)
                  <div className="landing-package-cols-1-size-rupee">
                    <i class="fa-solid fa-indian-rupee-sign"></i> 1,399/-
                  </div>
                </div>
              </div>
              <div className="landing-package-cols-1-size-large">
                <div>
                  <i class="fa-solid fa-dog"></i>
                </div>
                <div className="landing-package-cols-1-size-text">
                  Large Size (Above 50cm)
                  <div className="landing-package-cols-1-size-rupee">
                    <i class="fa-solid fa-indian-rupee-sign"></i> 1,499/-
                  </div>
                </div>
              </div>
            </div> */}
            <div className="landing-package-cols-1-button">
              {" "}
              <Link onClick={onClose}  to="">Book Appointment</Link>
            </div>
          </div>
          {/* ------------------------- col end ---------------------- */}

          {/* ------------------------- col start ---------------------- */}
          <div className="landing-package-cols-1">
            <div className="landing-package-cols-1-heading-1">
              <span>CAT FULL BODY GROOMING</span>
            </div>
            <div className="landing-package-cols-1-heading-2">
              {/* <span>Rs.3,499</span> Rs.1,799 */}

              
              <span><s>Rs.2,899</s></span> &nbsp;

              <span>
              <span>
            Rs.<CountUp start={2899} end={1799} duration="3" enableScrollSpy />

              </span>
              </span>
              
              
              
            </div>
            <div className="landing-package-cols-1-points">
              {/* ----------- points ------------------- */}
              <div className="landing-package-cols-1-single-point">
                <div>
                  <i class="fa-solid fa-paw"></i>
                </div>
                Full Body Haircut
              </div>
              <div className="landing-package-cols-1-single-point">
                <div>
                  <i class="fa-solid fa-paw"></i>
                </div>
                Bath & Blow Dry
              </div>
              <div className="landing-package-cols-1-single-point">
                <div>
                  <i class="fa-solid fa-paw"></i>
                </div>
                Mouth Spray
              </div>
              <div className="landing-package-cols-1-single-point">
                <div>
                  <i class="fa-solid fa-paw"></i>
                </div>
                Teeth Cleaning
              </div>

              {/* ----------- view more start ------------ */}

              {catFull && (
                <div>
                  <div className="landing-package-cols-1-single-point">
                    <div>
                      <i class="fa-solid fa-paw"></i>
                    </div>
                    Combing & Brushing
                  </div>
                  <div className="landing-package-cols-1-single-point">
                    <div>
                      <i class="fa-solid fa-paw"></i>
                    </div>
                    Ear & Eyes Cleaning
                  </div>
                  <div className="landing-package-cols-1-single-point">
                    <div>
                      <i class="fa-solid fa-paw"></i>
                    </div>
                    Sanitary Clipping
                  </div>
                </div>
              )}

              {/* ----------- view more end ------------ */}

              <button onClick={catFullReadMoreLess}>
                {catFull ? "View Less" : "View More"}
              </button>
            </div>
            <div className="landing-package-cols-1-heading-3">
              Limited Time Offer
            </div>
            {/* <div className="landing-package-cols-1-size">
              <div className="landing-package-cols-1-size-small">
                <div>
                  <i class="fa-solid fa-dog"></i>
                </div>
                <div className="landing-package-cols-1-size-text">
                  Small Size (Under 30cm)
                  <div className="landing-package-cols-1-size-rupee">
                    <i class="fa-solid fa-indian-rupee-sign"></i> 1,799/-
                  </div>
                </div>
              </div>
              <div className="landing-package-cols-1-size-medium">
                <div>
                  <i class="fa-solid fa-dog"></i>
                </div>
                <div className="landing-package-cols-1-size-text">
                  Medium Size (30cm-50cm)
                  <div className="landing-package-cols-1-size-rupee">
                    <i class="fa-solid fa-indian-rupee-sign"></i> 1,999/-
                  </div>
                </div>
              </div>
              <div className="landing-package-cols-1-size-large">
                <div>
                  <i class="fa-solid fa-dog"></i>
                </div>
                <div className="landing-package-cols-1-size-text">
                  Large Size (Above 50cm)
                  <div className="landing-package-cols-1-size-rupee">
                    <i class="fa-solid fa-indian-rupee-sign"></i> 2,199/-
                  </div>
                </div>
              </div>
            </div> */}
            <div className="landing-package-cols-1-button">
              {" "}
              <Link onClick={onClose}  to="">Book Appointment</Link>
            </div>
          </div>
          {/* ------------------------- col end ---------------------- */}

          {/* ------------------------- col start ---------------------- */}
          <div className="landing-package-cols-1">
            <div className="landing-package-cols-1-heading-1">
              <span>CAT DELUXE BODY GROOMING</span>
            </div>
            <div className="landing-package-cols-1-heading-2">
              {/* <span>Rs.4,799</span> Rs.2,399 */}

              
              <span><s>Rs.3,599</s></span> &nbsp;

              <span>
              <span>
            Rs.<CountUp start={3599} end={2399} duration="3" enableScrollSpy />

              </span>
              </span>
              
              
              
            </div>
            <div className="landing-package-cols-1-points">
              {/* ----------- points ------------------- */}
              <div className="landing-package-cols-1-single-point">
                <div>
                  <i class="fa-solid fa-paw"></i>
                </div>
                Designer Haircut
              </div>
              <div className="landing-package-cols-1-single-point">
                <div>
                  <i class="fa-solid fa-paw"></i>
                </div>
                Medicated Bath & Blow Dry
              </div>
              <div className="landing-package-cols-1-single-point">
                <div>
                  <i class="fa-solid fa-paw"></i>
                </div>
                Teeth Cleaning
              </div>
              <div className="landing-package-cols-1-single-point">
                <div>
                  <i class="fa-solid fa-paw"></i>
                </div>
                Ear & Eyes Cleaning
              </div>

              {/* ----------- view more start ------------ */}

              {catDeluxe && (
                <div>
                  <div className="landing-package-cols-1-single-point">
                    <div>
                      <i class="fa-solid fa-paw"></i>
                    </div>
                    Combing & Brushing
                  </div>
                  <div className="landing-package-cols-1-single-point">
                    <div>
                      <i class="fa-solid fa-paw"></i>
                    </div>
                    Sanitary Clipping
                  </div>
                  <div className="landing-package-cols-1-single-point">
                    <div>
                      <i class="fa-solid fa-paw"></i>
                    </div>
                    Anti-Tick Spray
                  </div>
                  <div className="landing-package-cols-1-single-point">
                    <div>
                      <i class="fa-solid fa-paw"></i>
                    </div>
                    Full Body Massage
                  </div>
                  <div className="landing-package-cols-1-single-point">
                    <div>
                      <i class="fa-solid fa-paw"></i>
                    </div>
                    Mouth Freshener Spray
                  </div>
                </div>
              )}

              {/* ----------- view more end ------------ */}

              <button onClick={catDeluxeReadMoreLess}>
                {catDeluxe ? "View Less" : "View More"}
              </button>
            </div>
            <div className="landing-package-cols-1-heading-3">
              Limited Time Offer
            </div>
            {/* <div className="landing-package-cols-1-size">
              <div className="landing-package-cols-1-size-small">
                <div>
                  <i class="fa-solid fa-dog"></i>
                </div>
                <div className="landing-package-cols-1-size-text">
                  Small Size (Under 30cm)
                  <div className="landing-package-cols-1-size-rupee">
                    <i class="fa-solid fa-indian-rupee-sign"></i> 2,399/-
                  </div>
                </div>
              </div>
              <div className="landing-package-cols-1-size-medium">
                <div>
                  <i class="fa-solid fa-dog"></i>
                </div>
                <div className="landing-package-cols-1-size-text">
                  Medium Size (30cm-50cm)
                  <div className="landing-package-cols-1-size-rupee">
                    <i class="fa-solid fa-indian-rupee-sign"></i> 2,599/-
                  </div>
                </div>
              </div>
              <div className="landing-package-cols-1-size-large">
                <div>
                  <i class="fa-solid fa-dog"></i>
                </div>
                <div className="landing-package-cols-1-size-text">
                  Large Size (Above 50cm)
                  <div className="landing-package-cols-1-size-rupee">
                    <i class="fa-solid fa-indian-rupee-sign"></i> 2,799/-
                  </div>
                </div>
              </div>
            </div> */}
            <div className="landing-package-cols-1-button">
              {" "}
              <Link onClick={onClose}  to="">Book Appointment</Link>
            </div>
          </div>
          {/* ------------------------- col end ---------------------- */}
        </div>
      </div>

      {/* ----------------------------------- cat packages end ------------------------------ */}



      <div className="landing-header-numbers">
        <div className="landing-header-numbers-col">
          <div className="landing-header-numbers-col-inc">
            <CountUp end={10} duration="5" enableScrollSpy />+
          </div>
          <div className="landing-header-numbers-col-txt">
            Years of experience
          </div>
        </div>

        <div className="landing-header-numbers-col">
          <div className="landing-header-numbers-col-inc">
            <CountUp end={4} duration="4" enableScrollSpy />
            <span>.<CountUp end={9} duration="5" enableScrollSpy /> </span>/5
          </div>
          <div className="landing-header-numbers-col-txt">
            {" "}
            Rating on Google
          </div>
        </div>

        <div className="landing-header-numbers-col">
          <div className="landing-header-numbers-col-inc">
            <CountUp end={25000} enableScrollSpy />+
          </div>
          <div className="landing-header-numbers-col-txt">Happy Customers</div>
        </div>

        <div className="landing-header-numbers-col">
          <div className="landing-header-numbers-col-inc">
            <CountUp end={10} duration="5" enableScrollSpy />+
          </div>
          <div className="landing-header-numbers-col-txt">Available Cities</div>
        </div>
      </div>


    </div>
  )
}

export default OldComponents