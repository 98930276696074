import React from 'react'
import why from "../../media/images/Dog-Spa-Noida.png"


const WhyUsComponent = () => {
  return (
    <div>

<div id='why-us' className="why-us">
        <div className="why-us-header">
          <div className="why-us-head-heading">Why <span>Fluffy Touch</span> ?</div>
          <div className="why-us-head-text">
            We have dedicated team proper trained and experts in grooming they keep your pets happy, healthy and safe while come at
            your house to provide any service.
          </div>
        </div>

        <div className="why-us-content">
        <div className="why-us-content-img"><img src={why} alt="" /></div>
          <div className="why-us-content-cols">
            <div className="why-us-content-col-1">

              <div className="why-us-content-col-1-col">
                <div className="why-us-content-col-1-text">
                  <div className="why-us-content-col-text-heading">Trained professionals</div>
                  <div className="why-us-content-col-text-txt">Our all professionals are properly trained and certified</div>
                </div>
                <div className="why-us-content-col-1-icon"></div>
              </div>

              <div className="why-us-content-col-1-col">
                <div className="why-us-content-col-1-text">
                  <div className="why-us-content-col-text-heading">Latest Equipments</div>
                  <div className="why-us-content-col-text-txt">We always use latest and modern equipments for our salon</div>
                </div>
                <div className="why-us-content-col-1-icon"></div>
              </div>
              

              <div className="why-us-content-col-1-col">
                <div className="why-us-content-col-1-text">
                  <div className="why-us-content-col-text-heading">Branded products</div>
                  <div className="why-us-content-col-text-txt">We love your pets like baby and use branded shampoo, oil etc</div>
                </div>
                <div className="why-us-content-col-1-icon"></div>
              </div>            
              
            </div>

            <div className="why-us-content-col-2">
              <img src={why} alt="why us " />
            </div>

            <div className="why-us-content-col-3">
              
            <div className="why-us-content-col-3-col">
                <div className="why-us-content-col-3-text">
                  <div className="why-us-content-col-text-heading">We are pet lovers</div>
                  <div className="why-us-content-col-text-txt">We pamper your pets like ours, because we are also pet lovers</div>
                </div>
                <div className="why-us-content-col-3-icon"></div>
              </div>
              

              <div className="why-us-content-col-3-col">
                <div className="why-us-content-col-3-text">
                  <div className="why-us-content-col-text-heading">Doorstep service</div>
                  <div className="why-us-content-col-text-txt">Our team provide services on location as preferred by you.</div>
                </div>
                <div className="why-us-content-col-3-icon"></div>
              </div>

              <div className="why-us-content-col-3-col">
                <div className="why-us-content-col-3-text">
                  <div className="why-us-content-col-text-heading">7 days availability</div>
                  <div className="why-us-content-col-text-txt">We always work and happy to give our service 7 days.</div>
                </div>
                <div className="why-us-content-col-3-icon"></div>
              </div>

            </div>
            
          </div>
        </div>

      </div>
      
    </div>
  )
}

export default WhyUsComponent