import React from 'react'
import { Link } from 'react-router-dom'

const ConfirmBookingPage = () => {
  return (
    <div>
        <div className="error-page">
            <h1>Thanks For Booking</h1>
            <h2>Our team will contact you shortly</h2>
            <h2>Contact : <Link to="tel:+91 9658-189-189">+91 9658-189-189</Link></h2>
            <h3><Link to="/">go to home</Link></h3>
        </div>
    </div>
  )
}

export default ConfirmBookingPage