import React from "react";
import { Link } from "react-router-dom";

const WhatsappButton = () => {
  return (
    <div className="whatsapp-button">
      <Link className="whatsapp-button-icon" target="_blank" to="https://wa.me/+919658189189?text=Hello ! I'm Interested in Doorstep Pet Service. Please contact me.">
        <i class="fa-brands fa-whatsapp"></i>
      </Link>
    </div>
  );
};

export default WhatsappButton;
