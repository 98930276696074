// import logo from './logo.svg';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// ---------------------------------------------- PAGES -------------------------------------------------



// ---------------------------------------------- CSS -------------------------------------------------
import './App.css';
import './css/user/Navbar.css';
import './css/user/HomePage.css';
import './css/user/Header.css';
// import './css/user/Footer.css';
import './css/user/Testimonial.css';
import './css/user/OfferSlider.css';
import './css/user/LandingPage.css';
import './css/user/WhyUsComponent.css';
import './css/user/Buttons.css';
import './css/user/Animation.css';
import './css/user/FixComponent.css';
import './css/user/LandingForm.css';
import './css/user/GroomingPage.css';
import './css/user/Loading.css';
import './css/user/PopupOffer.css';
import './css/user/GroomingPackageComponent.css';
import './css/user/KeyWords.css';
import './css/user/ClientSaysComponent.css';


import './css/other/Other.css';
import LandingPage from './pages/user/LandingPage';
// import OtherPage from './pages/other/OtherPage';
import ErrorPage from './pages/user/ErrorPage';
import ConfirmBookingPage from './pages/user/ConfirmBookingPage';
import OldComponents from './components/user/OldComponents';
import ChandigarhLandingPage from './pages/user/ChandigarhLandingPage';


function App() {
  return (
    <div className="web-app">
      <BrowserRouter basename='/'>
      <Routes>
        
      <Route path='/' element={<LandingPage />}></Route>
      <Route path='/chandigarh' element={<ChandigarhLandingPage />}></Route>
      
      <Route path='/booking-confirm' element={<ConfirmBookingPage />}></Route>
      <Route path='*' element={<ErrorPage />}></Route>

      <Route path='/other' element={<OldComponents />}></Route>


      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
