import React from 'react'
import { Link } from 'react-router-dom'

const CallButton = () => {
  return (
    <div className="call-button">
      <Link className="call-button-icon"  target="_blank" to="tel:+919658189189">
              <i class="fa-solid fa-phone-volume"></i>
          </Link>
    </div>
  )
}

export default CallButton 